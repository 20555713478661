import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import $ from 'jquery';
import AmsPlayer from './player_ams';
import PlayerApi from '@/support/playerApi';
import { ServerDataModule } from '@/store/serverData';
import { IVideoPlayer, IVideo } from '@/interfaces';

@Component
export default class PlayerComponent extends Vue {
    get serverData() {
        return ServerDataModule.serverData;
    }

    streamId: string = null;
    url: string = null;
    player: IVideoPlayer = null;
    show: boolean = false;
    containerId: string = null;
    removeOnError = false;

    events = {
        playing: $.Deferred(),
        ended: $.Deferred(),
    };

    @Prop({ default: null })
    video: IVideo;

    @Prop({ default: 640 })
    width: number;

    @Prop({ default: 480 })
    height: number;

    @Prop({ default: false })
    skipLoad: boolean;

    @Prop({ default: true })
    autoPlay: boolean;

    @Prop({ default: false })
    mute: boolean;

    @Prop({ default: true })
    showTitle: boolean;

    @Watch('video')
    onVideoChanged(value: IVideo, oldValue: IVideo) {
        Debug.log('player video changed:', value);
    }

    mounted() {
        let $el = $(this.$el);

        Debug.log('player', this.video.id, this.video, this.width, this.height, $el.width(), $el.height(), this.$el);
        if (this.video) {
            this.containerId = 'player-container-' + this.video.id;

            this.load();
        }

        this.$emit('mounted', this.video);
    }

    beforeDestroy() {
        if (this.player)
            this.player.stopVideo();
    }

    resizePlayer(width: number, height: number) {
        this.width = width;
        this.height = height;

        let $el = $(this.$el);
        Debug.log("player", "resizePlayer", this.video.id, this.width + ' x ' + this.height, this.player, this.$el);

        $el.width(this.width);
        $el.height(this.height);

        if (this.player) {
            this.player.resizeVideo(this.width, this.height);
        }
    }

    async load() {
        if (!this.video) {
            Debug.error('player load missing video');
            return false;
        }

        if (this.skipLoad) {
            this.resizePlayer(this.width, this.height);
            Debug.log("player", "skipLoad", this.video.id, this.width, ' x ', this.height, this.$el);
            return false;
        }

        let response = await this.getStreamUrl();
        if (response) {
            this.show = true;
        }
    }

    async getStreamUrl() {
        try {
            let response = await PlayerApi.getStreamUrl(this.video);

            Debug.log("player stream url", response, this.video);
            this.video.streamUrl = response.streamUrl;
            this.video.token = response.token;

            this.player = new AmsPlayer(this.video.id, this.width, this.height);
            this.player.callbacks.onError = this.onError;
            this.player.callbacks.onEnded = this.onEnded;
            this.player.callbacks.onPlaying = this.onPlaying;
            this.player.mute = this.mute;
            this.player.playVideo(this.video, this.autoPlay);
            this.video.player = this.player;

            return true;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('Failed aquire video: ' + message);
            return null;
        } finally {

        }
    }

    onPlaying(video: IVideo) {
        Debug.log("onPlaying", this.video.studio, this.video.id, this.video.id);
        this.events.playing.resolve(true);

        this.$emit('playing', this.video);
    }

    onEnded(video: IVideo) {
        Debug.log("onEnded", this.video.studio, this.video.id, this.video.id);
        this.events.playing.resolve(false);
        this.events.ended.resolve(true);

        this.show = false;
        if (this.player)
            this.player.stopVideo();

        this.$emit('ended', this.video);
    }

    onError(video: IVideo, error: string, code: number, message: string) {
        Debug.error("onError", error, code, message, video.studio, video.id, video.id);
        this.show = false;

        if (this.removeOnError) {
            let $el = $(this.$el);
            $el.width(0);
            $el.height(0);
            $el.remove();
        }

        if (this.player)
            this.player.stopVideo();

        this.$emit('error', this.video);
    }

    stopPlayer() {
        if (this.player)
            this.player.stopVideo();
    }
}
